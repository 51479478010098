@use "../../style-utils/variables.scss" as v;


.view-home {
    width: 100%;
    height: 100vh;
    padding: 1em;
    background-color: v.$bg-blue;
    background-image: url(../../assets/bgg.jpg);
    background-blend-mode: multiply;
    background-position: left;

    main {
        height: 70%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    label {
        display: flex;
        flex-direction: column;
        margin-block-end: 1em;
    }

    input {
        font-family: v.$red-hat;
        padding-block: .7em;
        padding-inline: .5em 10em;
        margin-top: .5em;
    }

    button {
        font-family: v.$red-hat;
        font-size: .9em;
        background-color: transparent;
        padding: .5em 1em;
        border: solid 1px;
        margin-right: 1em;

        &.search {
            background-color: v.$theme-blue;
            color: v.$bg-blue;
            border-color: v.$theme-blue;

            &:focus,
            &:active,
            &:hover {
                background-color: rgb(3, 101, 158);
            }
        }

        &.test {
            color: v.$text-gray;
            border-color: v.$text-gray-light;

            &:hover {
                border-color: v.$theme-blue;
                color: v.$theme-blue;
            }
        }
    }

    .logo {
        display: flex;
        align-items: center;

        span {
            margin-left: .3em;
            font-size: 1.15em;
            font-weight: 500;
        }
    }
}