@import url("https://fonts.googleapis.com/css2?family=Red+Hat+Mono:wght@300;400;500;600;700&display=swap");

// Colors

$theme-blue: rgb(2, 126, 197);
$bg-blue: rgb(242, 250, 255);
$heading-gray: rgb(55, 55, 55);
$text-gray: rgb(94, 94, 94);
$line-gray: rgba(0, 0, 0, 0.089);
$text-gray-light: rgb(144, 144, 144);
$white: rgb(255, 255, 255);
$black: rgb(0, 0, 0);

// Fonts

$red-hat: "Red Hat Mono", sans-serif;
