@use  "../style-utils/mixins.scss" as m;
@use "../style-utils/variables.scss" as v;

.repo-card {
    background-color: v.$white;
    padding: 2.5em 2em;
    border-radius: 15px;
    box-shadow: 0 0 0 1px v.$line-gray;

    &-content {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    &-heading {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 2em;

        & h3  {
            margin: 0;
            text-transform: capitalize;
            font-size: 1rem;
            color: v.$heading-gray;
        }
    }

    &__icon {
        @include m.create-btn-icon(url(../assets/repo.svg), 1.5em, 1.5em);
        margin-right: 1em;
    }

    &-footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 2em;
    }

    &__date {
        margin: 0;
        font-size: 0.85rem;
        color: v.$text-gray-light;
    }


    &__link {
        filter: grayscale(1) opacity(0.8);
        
        &:hover,
        &:active,
        &:focus {
            filter: grayscale(0) opacity(1);
        }
    }

}